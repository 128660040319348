<template>
  <div class="page">
    <h1><w-icon>mdi mdi-filmstrip</w-icon>動画</h1>
    <div class="content">
      <p class="center">
        <a href="https://www.youtube.com/watch?v=irffrasQVQI&list=PLy9q_uyvcSSvNBP3qxSLevB2nnBi_9Zni" target="_blank" rel="noopener noreferrer">
          Youtube わけりんぐ 再生リスト
          <w-icon>mdi mdi-open-in-new</w-icon>
        </a>
      </p>
    </div>
    <div class="next-link">
      <w-flex align-center justify-space-evenly>
        <router-link to="/item/wake-ring/merit/">仕組みとメリット</router-link>
        <router-link to="/item/wake-ring/how-to-use/">ＣＧシミュレーション</router-link>
        <router-link to="/item/wake-ring/research/">有効性</router-link>
        <a href="https://minne.com/@mihiraku" target="_blank" rel="noopener noreferrer">ショップ <w-icon>mdi mdi-open-in-new</w-icon></a>
      </w-flex>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
